/** @format */

* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  font-family: "Poppins", sans-serif !important;
}
::-moz-selection {
  background: #a2f6c5;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #a2f6c5;
  color: #fff;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #20ed9b;
}

::-webkit-scrollbar-thumb:hover {
  background: #35e39d;
}
body {
  background: #fff;

  -webkit-font-smoothing: antialiased;
  /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
}

.gov {
  padding-bottom: 120px;
  background: linear-gradient(180deg, #85f6b4 0%, #a2f6c5 29%, #fff 100%);
}

.tile {
  margin-top: 10px !important;
  background: #fff;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.tile h3,
.tile h4 {
  margin: 0;
}

.proposals {
  margin-top: -70px;
}

.proposals i {
  font-size: 10px;
  position: relative;
  top: -2px;
  opacity: 0.5;
}

.proposals .badge {
  background: none;
  border-radius: 5px;
  padding: 4px 10px 5px 10px;
  font-weight: bold;
  top: -2px;
  position: relative;
}

.proposals .badge-warning {
  border: 1px solid #f9a825;
  color: #f9a825;
}

.proposals .badge-success {
  border: 1px solid #4caf50;
  color: #4caf50;
}

.proposalsLink:hover {
  background: #f5f5f5;
}

.proposalsLink .link {
  cursor: pointer;
}

.fs-24 {
  font-size: 24px;
}

.fs-20 {
  font-size: 20px;
}

.swap-nav {
//   background: #3b8e7c;
  //   background: #95d8c8;
  /* border-top: 2px dashed #3b8e7c; */
  /* border-bottom: 2px dashed #3b8e7c; */
  /* position: absolute; */
  margin-bottom: -124px;
  /* padding-top: 10px; */
  padding-bottom: 10px;
  /* background: #113f65; */
}
.navbar{
    padding: 0px !important;
}
.navbar-light .navbar-toggler {
  color: #fff;
}

.swap-nav .navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-brand {
  /* color: #3b8e7c; */
  font-weight: 600;
  color: #fff;
}

.nav-link {
  cursor: pointer;
}
.round-btn {
  cursor: pointer !important;
  position: relative;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 25px;
  color: #fff !important;
  background-color: #0ecb81;
  padding: 0px 20px;
  border-radius: 5px;
  font-weight: 400;
  display: inline-block;
}
